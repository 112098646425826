import Cookies from "js-cookie";
import jwt from "jwt-decode";

const checkLogin = () => {
  const userToken = Cookies.get("adminToken");
  let decodedToken;
  if (userToken) {
    try {
      decodedToken = jwt(userToken);
      // const expiredDate = decodedToken?.exp * 1000;
      // if (Date.now() > expiredDate) {
      //   return { isLogin: false, errorMessage: "expired-token" };
      // }
      return { isLogin: true, role: decodedToken?.role };
    } catch (err) {
      return { isLogin: false, errorMessage: "invalid-token" };
    }
  }
  return { isLogin: false, errorMessage: "login-first" };
};

const isLocalhost =
  window.location.hostname === "localhost" ||
  window.location.hostname === "127.0.0.1";

const clearToken = () => {
  Cookies.remove("request-uid");
  Cookies.remove("adminToken");
  Cookies.remove("adminRefreshToken");
};

const setToken = ({ uid, token, refreshToken }) => {
  Cookies.set("request-uid", uid);
  Cookies.set("adminToken", token);
  Cookies.set("adminRefreshToken", refreshToken);
};

const getUid = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get("request-uid");
};

const getToken = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get("adminToken");
};

const getRefreshToken = () => {
  if (typeof window === `undefined`) return undefined;
  return Cookies.get("adminRefreshToken");
};

const isTokenExpired = (token) => jwt(token)?.exp <= Date.now() / 1000;

export {
  setToken,
  getUid,
  getToken,
  getRefreshToken,
  isTokenExpired,
  checkLogin,
  clearToken,
  isLocalhost,
};
