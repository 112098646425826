const DEVICE = {
  LAPTOP: "Laptop",
  TABLET: "Tablet",
  MOBILE: "Mobile",
};

const ROUTE = {
  ROOT: "/",
  LOGIN: "/login",
  RESEARCHER: "/researcher",
  RESEARCHER_DETAIL: "/researcher/:id",
  PARTICIPANT: "/participant",
  PARTICIPANT_DETAIL: "/participant/:id",
  USER_VERIFICATION: "/user-verification",
  USER_VERIFICATION_DETAIL: "/user-verification/:id",
  SURVEY_APPROVAL: "/survey-approval",
  SURVEY_DETAIL: "/survey-approval/:id",
  PREVIEW_SURVEY: "/survey-approval/:id/preview",
  CASHOUT_APPROVAL: "/cashout-approval",
  CASHOUT_APPROVAL_DETAIL: "/cashout-approval/:id",
  CASHOUT_APPROVED: "/cashout-approved",
  EXPORT_HISTORY: "/export-history",
  TOPUP_HISTORY: "/topup-history",
  REWARDS_APPROVAL: "/reward-approval",
  REWARD_DETAIL: "/reward-approval/detail/:id",
  ICREDIT_APPROVAL: "/credit-approval",
  ICREDIT_APPROVAL_REQUEST: "/credit-approval/request/:id",
  ICREDIT_APPROVAL_TOPUP: "/credit-approval/topup/:id",
  ICREDIT_APPROVAL_HISTORY: "/credit-approval/history/:id",
  CHANGE_PASSWORD: "/change-password",
  FORGOT_PASSWORD: "/forgot-password",
  EMAIL_SUBSCRIPTION: "/email-subscription",
  UNAUTHEN: "/unauthen",
};

const FORMAT = {
  DATE_FORMAT_WITH_TIME: "DD MMM YYYY, HH:mm",
  DATE_PICKER_FORMAT: "DD MMM YYYY",
};

const PROJECT_STATUS = {
  ACTIVE: "ACTIVE",
  APPROVED: "APPROVED",
  APPROVING: "APPROVING",
  COMPLETED: "COMPLETED",
  DRAFT: "DRAFT",
  PAUSED: "PAUSED",
  PENDING: "PENDING",
  STOPPED: "STOPPED",
  REJECT: "REJECT",
};

const CASHOUT_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DISAPPROVE: "DISAPPROVE",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  CANCELLED: "CANCELLED",
  EXPORTED: "EXPORTED",
};

const REWARD_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DISAPPROVE: "DISAPPROVE",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  CANCELLED: "CANCELLED",
  EXPORTED: "EXPORTED",
};

const SURVEY_STATUS = {
  PENDING: 0,
  APPROVE: 1,
  REJECT: 2,
};

const CONFIG_VALUE = {
  TABLE_LIMIT: 20,
  PROJECT_LIST_LIMIT: 10,
  USER_VERIFY_LIST_LIMIT: 20,
  PROJECT_APPROVAL_LIST_LIMIT: 20,
  RESEARCHER_LIST_LIMIT: 20,
  PARTICIPANT_LIST_LIMIT: 20,
  RESEARCHER_PROJECT_LIST: 10,
  CASHOUT_LIST_LIMIT: 20,
  TOPUP_LIST_LIMIT: 20,
  REWARD_LIST_LIMIT: 20,
  CREDIT_LIST_LIMIT: 20,
  CREDIT_MEMBER_LIST_LIMIT: 10,
  CREDIT_HISTORY_LIST_LIMIT: 10,
  EMAIL_SUBSCRIPTION: 10,
};

const PROJECT_APPROVAL_STATUS = {
  APPROVED: "APPROVED",
  REJECT: "REJECT",
  ACTIVE: "ACTIVE",
};

const CASHOUT_APPROVAL_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DISAPPROVE: "DISAPPROVE",
  FAILED: "FAILED",
  SUCCESSFUL: "SUCCESSFUL",
  CANCELLED: "CANCELLED",
};

const LOGIC_TYPE = {
  CUBE: "CUBE",
  DUMMY_CUBE: "DUMMY_CUBE",
  BRANCH: "BRANCH",
  SHUFFLE: "SHUFFLE",
  END_PROJECT: "END_PROJECT",
};

const BRANCH_TYPE = {
  QUESTION: "QUESTION",
  TRUE_FALSE: "TRUE_FALSE",
};

const CONDITION_MODE = {
  SCALE: "SCALE",
  COUNT: "COUNT",
  RECODE: "RECODE",
  SPECIFIC: "SPECIFIC",
};

const PAYMENT_TYPE = {
  CASH: "CASH",
  CREDIT: "ICREDIT",
};

export {
  DEVICE,
  ROUTE,
  FORMAT,
  PROJECT_STATUS,
  CASHOUT_STATUS,
  REWARD_STATUS,
  SURVEY_STATUS,
  CONFIG_VALUE,
  PROJECT_APPROVAL_STATUS,
  CASHOUT_APPROVAL_STATUS,
  LOGIC_TYPE,
  BRANCH_TYPE,
  CONDITION_MODE,
  PAYMENT_TYPE,
};
