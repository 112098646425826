import { useCallback, useContext } from "react";
import { Space, Avatar, Dropdown, Menu, notification } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
// import constant
import { ROUTE } from "../../config/common";
import { clearToken } from "../../utilities/authenUtil";
// import context
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { ACTIONS } from "../../stateManagement/actions/commonAction";
// import style
import "./Navbar.css";
// import for api
import { useLazyQuery } from "@apollo/client";
import { LOGOUT } from "../../graphql/query";

const CurrentUser = () => {
  const { currentUser } = useContext(CommonContext);
  const dispatch = useContext(CommonDispatchContext);
  const name = currentUser?.email || "-";
  const navigate = useNavigate();
  const { t: getText, i18n } = useTranslation();

  const logoutCallback = useCallback(() => {
    clearToken();
    dispatch({ type: ACTIONS.CLEAR_CURRENT_USER });
    navigate(ROUTE.LOGIN);
  }, [dispatch, navigate]);

  const [logout] = useLazyQuery(LOGOUT, {
    onCompleted: (data) => {
      notification.success({ message: data?.logout?.message });
      logoutCallback();
    },
  });
  const menu = (
    <Menu
      onClick={({ key }) => {
        switch (key) {
          case "logout":
            logout();
            break;
          default:
        }
      }}
      items={[
        {
          key: "logout",
          label: (
            <div id="logout-menu" className="dropdown-menu-title">
              {i18n.format(getText("logout"), "capitalFirstEach")}
            </div>
          ),
          icon: <img alt="" src="/images/navbar/logout.svg" />,
        },
      ]}
    />
  );
  return (
    <Dropdown
      overlayClassName="user-profile-dropdown"
      overlay={menu}
      trigger="click"
    >
      <div id="current-user-container" className="pointer">
        <Space>
          <Avatar
            className="user-icon"
            size={28}
            src={<img alt="" src="/images/common/user-icon.svg" />}
          />
          <div>
            <Space>
              <div className="username-title">{name}</div>
              <img alt="" src="/images/common/arrow-down.svg" />
            </Space>
          </div>
        </Space>
      </div>
    </Dropdown>
  );
};

const Navbar = () => {
  const { t: getText, i18n } = useTranslation();
  return (
    <div id="navbar-container">
      <div id="navbar-leading">
        <img alt="" src="/images/common/icon.svg" />
        <img alt="" src="/images/common/informata-title.svg" />
        <div id="admin-badge">{i18n.format(getText("admin"), "uppercase")}</div>
      </div>
      <div id="navbar-trailing">
        <Space size="large">
          <CurrentUser />
        </Space>
      </div>
    </div>
  );
};

export default Navbar;
