import React from "react";
import "../../styles/commonFooter.css";

const CommonFooter = () => {
  return (
    <div className="footer-label-detail">
      <div className="company-label">© Informata 2020</div>
      <div className="company-product-description">
        Informata is Online Projects Suite account with the appropriate
        permission is required.
      </div>
    </div>
  );
};

export default CommonFooter;
