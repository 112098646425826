import { useContext } from "react";
import { Modal, Button, Space, Form } from "antd";
import { ACTIONS } from "../../stateManagement/actions/commonAction";
import { MODAL_TYPE } from "../../config/customModalConfig";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { isFunction } from "../../utilities/util";
import { useTranslation } from "react-i18next";
// style
import "./CustomModal.css";

const CustomModal = ({ className }) => {
  const { t: getText, i18n } = useTranslation();
  const { isShowModal, modalConfig } = useContext(CommonContext);
  const commonDispatch = useContext(CommonDispatchContext);
  const [form] = Form.useForm();
  const {
    type,
    buttonText,
    buttonType,
    callback,
    title,
    description,
    className: configClassName,
    ...otherModalProps
  } = modalConfig || {};
  const renderModalContent = () => {
    switch (type) {
      case MODAL_TYPE.PROJECT_COMPLETE:
        return (
          <div className="project-modal-content-container">
            <div className="project-modal-title">
              {i18n.format(getText("project-completed"), "capitalFirstEach")}
            </div>
            <div className="project-modal-earn">
              {i18n.format(getText("just-earn", { num: description || 0 }))}
            </div>
            <div className="project-modal-describe">
              {i18n.format(getText("complete-project-describe"))}
            </div>
          </div>
        );
      case MODAL_TYPE.DESCRIBE:
        return (
          <div className="description-modal-content-container">
            {description}
          </div>
        );
      case MODAL_TYPE.INVITE_MEMBER:
        return (
          <>
            <div id="invite-title">{title}</div>
            {description}
          </>
        );
      case MODAL_TYPE.CONFIRM:
        return (
          <div className="confirm-modal-content-container">
            <div className="confirm-modal-title">{title}</div>
            <div className="w-full">
              <div className="confirm-modal-description">{description}</div>
              <Space
                direction="vertical"
                className="w-full"
                style={{ marginTop: 20 }}
              >
                <Button
                  className="accept-button"
                  onClick={() => {
                    if (isFunction(callback)) {
                      callback();
                    }
                  }}
                >
                  {buttonText || i18n.format(getText("confirm"), "capitalize")}
                </Button>
                <Button
                  className="cancel-button"
                  onClick={() => {
                    commonDispatch({ type: ACTIONS.HIDE_CONFIRM_MODAL });
                  }}
                >
                  {i18n.format(getText("cancel"), "capitalize")}
                </Button>
              </Space>
            </div>
          </div>
        );
      default:
    }
  };

  return (
    <Modal
      className={`custom-modal ${className || configClassName}`}
      open={isShowModal}
      onCancel={() => commonDispatch({ type: ACTIONS.HIDE_CONFIRM_MODAL })}
      footer={null}
      closeIcon={null}
      destroyOnClose
      {...otherModalProps}
    >
      <div className="custom-modal-content-container">
        {renderModalContent()}
        {[MODAL_TYPE.PROJECT_COMPLETE, MODAL_TYPE.DESCRIBE].includes(type) && (
          <Button
            type={type === MODAL_TYPE.PROJECT_COMPLETE ? "success" : buttonType}
            onClick={() => {
              if (isFunction(callback)) {
                callback();
              }
            }}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Modal>
  );
};

export default CustomModal;
