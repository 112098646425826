// import handler function
import {
  setCurrentUser,
  clearCurrentUser,
  showModal,
  hideModal,
  showGlobalLoading,
  hideGlobalLoading,
  showTopupModal,
  hideTopupModal,
  setAccountSelected,
  hideAccountSelected,
} from "../handlers/commonHandler";
// import handler function
import { ACTIONS } from "../actions/commonAction";

const CommonReducer = (state, { type, ...action }) => {
  switch (type) {
    case ACTIONS.SET_CURRENT_USER:
      return setCurrentUser(state, action);
    case ACTIONS.CLEAR_CURRENT_USER:
      return clearCurrentUser(state);
    case ACTIONS.SHOW_CONFIRM_MODAL:
      return showModal(state, action);
    case ACTIONS.HIDE_CONFIRM_MODAL:
      return hideModal(state);
    case ACTIONS.SHOW_GLOBAL_LOADING:
      return showGlobalLoading(state, action);
    case ACTIONS.HIDE_GLOBAL_LOADING:
      return hideGlobalLoading(state, action);
    case ACTIONS.SHOW_TOPUP_MODAL:
      return showTopupModal(state, action);
    case ACTIONS.HIDE_TOPUP_MODAL:
      return hideTopupModal(state);
    case ACTIONS.SET_ACCOUNT_SELECTED:
      return setAccountSelected(state, action);
    case ACTIONS.HIDE_ACCOUNT_SELECTED:
      return hideAccountSelected(state, action);
    default:
      return state;
  }
};

export default CommonReducer;
