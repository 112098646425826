import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { notification } from "antd";
import { ROUTE } from "../config/common";
import {
  clearToken,
  getRefreshToken,
  getToken,
  getUid,
  isTokenExpired,
  setToken,
} from "./authenUtil";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import i18n from "i18next";

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: `refreshTokenAdmin`,
  isTokenValidOrUndefined: () => {
    const token = getToken();
    return !token || (token && !isTokenExpired(token));
  },
  fetchAccessToken: () => {
    const query = `
      query refreshTokenAdmin {
        refreshTokenAdmin {
          accessToken
          refreshToken
        }
      }
    `;
    return fetch(`/graphql`, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "refresh-token": getRefreshToken(),
      },
      body: JSON.stringify({
        query,
      }),
    });
  },
  handleFetch: (response) => {
    if (response.errors && response.errors.length) return;
    setToken({
      uid: response?.uid,
      token: response?.accessToken,
      refreshToken: response?.refreshToken,
    });
  },
  handleError: (err) => {
    console.error("[error] ", err);
    clearToken();
  },
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions, locations, path }) => {
      switch (extensions?.code) {
        case "UNAUTHENTICATED":
          if (window.location.pathname !== ROUTE.LOGIN) {
            clearToken();
            window.location.replace(
              `${ROUTE.LOGIN}?error=unauthen&redirect=${
                window?.location?.href || ""
              }`
            );
          }
          break;
        case "BAD_USER_INPUT":
          notification.error({
            message: i18n.t(`exception.${message}`),
          });
          break;
        default:
          notification.error({ message: message });
      }
    });
  }
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BASE_API,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  const requestUid = getUid();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "X-Request-ID": `${process.env.REACT_APP_NAME}-${
        requestUid ? requestUid.toUpperCase() : "LOGIN"
      }`,
    },
  };
});

const client = new ApolloClient({
  link: from([refreshTokenLink, errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    mutate: {},
  },
});

export default client;
