import { useEffect, useContext } from "react";
import { Navigate } from "react-router";
import { ROUTE } from "../../config/common";
import { checkLogin } from "../../utilities/authenUtil";
import {
  CommonContext,
  CommonDispatchContext,
} from "../../stateManagement/context/commonContext";
import { ACTIONS } from "../../stateManagement/actions/commonAction";

const ProtectedRoute = ({ children }) => {
  const dispatch = useContext(CommonDispatchContext);
  const { globalLoading } = useContext(CommonContext);
  const { isLogin, errorMessage } = checkLogin();
  const redirectPath = `${ROUTE.LOGIN}?error=${
    errorMessage || "unauthen"
  }&redirect=${window?.location?.href || ""}`;
  useEffect(() => {
    if (isLogin && globalLoading && dispatch) {
      dispatch({
        type: ACTIONS.HIDE_GLOBAL_LOADING,
      });
    }
  }, [isLogin, globalLoading, dispatch]);

  return isLogin ? children : <Navigate to={redirectPath} replace />;
};

export default ProtectedRoute;
