import { createContext, useReducer } from "react";
import CommmonReducer from "../reducers/commonReducer";
const initState = {
  currentUser: {},
  isShowModal: false,
  modalConfig: null,
  globalLoading: true,
  isTopupModal: false,
  accountSelected: {},
};

const CommonContext = createContext();
const CommonDispatchContext = createContext();

const CommonProvider = ({ children }) => {
  const [value, dispatch] = useReducer(CommmonReducer, initState);
  return (
    <CommonContext.Provider value={value}>
      <CommonDispatchContext.Provider value={dispatch}>
        {children}
      </CommonDispatchContext.Provider>
    </CommonContext.Provider>
  );
};

export { CommonContext, CommonDispatchContext, CommonProvider };
