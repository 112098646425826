/* eslint-disable no-useless-escape */
const isEmpty = (obj) =>
  [Object, Array].includes((obj || {}).constructor) &&
  !Object.entries(obj || {}).length;
const notEmpty = (obj) => !isEmpty(obj);
const isFunction = (checker) => typeof checker === "function";
const isNumber = (tester) => /^\d+$/.test(tester);
const notNumber = (tester) => !isNumber(tester);
const isEmail = (tester) =>
  tester.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
const getFlag = (country) => `https://countryflagsapi.com/png/${country}`;
const displayTextAmount = (val) => {
  return val.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

const validateThaiCitizenID = (id) => {
  // eslint-disable-next-line eqeqeq
  if (id.length != 13 || id.charAt(0).match(/[09]/)) return false;

  var sum = 0;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i)) * (13 - i);
  }

  // eslint-disable-next-line eqeqeq
  if ((11 - (sum % 11)) % 10 != parseInt(id.charAt(12))) {
    return false;
  }

  return true;
};

const trimDash = (value) => value?.split("-")?.join("");
const isPassword = (password) =>
  password.match(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/);

const isNumericKey = (event) => {
  const key = event.key;
  // Allow digits, decimal point, and some control keys like backspace
  return (
    /^[0-9.]$/.test(key) ||
    event.ctrlKey ||
    event.metaKey ||
    event.key === "Backspace"
  );
};
const isNumericValue = (event) => {
  const key = event.key;
  // Allow digits, decimal point, and some control keys like backspace
  return (
    /^[0-9]$/.test(key) ||
    event.ctrlKey ||
    event.metaKey ||
    event.key === "Backspace"
  );
};

export {
  getFlag,
  isEmpty,
  notEmpty,
  isFunction,
  isNumber,
  notNumber,
  isEmail,
  displayTextAmount,
  validateThaiCitizenID,
  trimDash,
  isPassword,
  isNumericKey,
  isNumericValue,
};
