import "./Sidebar.css";
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE } from "../../config/common";
import { CaretDownOutlined } from "@ant-design/icons";
import CommonFooter from "../common/CommonFooter";

const Sidebar = () => {
  const [showUserMgmtMenu, setShowMenu] = useState(true);
  const [showCashoutMhmtMenu, setShowCashoutMenu] = useState(true);
  const { t: getText, i18n } = useTranslation();
  const { pathname } = useLocation();
  const isSelectUserMgmt =
    pathname.includes(ROUTE.RESEARCHER) || pathname.includes(ROUTE.PARTICIPANT);
  const isSelectCashoutMhmtMenu =
    pathname.includes(ROUTE.CASHOUT_APPROVAL) ||
    pathname.includes(ROUTE.CASHOUT_APPROVAL_DETAIL) ||
    pathname.includes(ROUTE.CASHOUT_APPROVED) ||
    pathname.includes(ROUTE.EXPORT_HISTORY);

  useEffect(() => {
    if (!showUserMgmtMenu) {
      setShowMenu(isSelectUserMgmt);
    }
    if (!showCashoutMhmtMenu) {
      setShowCashoutMenu(isSelectCashoutMhmtMenu);
    }
  }, [pathname, setShowMenu, setShowCashoutMenu]);
  return (
    <div id="sidebar-container">
      <div id="sidebar-menu-container">
        <NavLink className="nav-menu" to={ROUTE.ROOT}>
          {i18n.format(getText("dashboard"), "uppercase")}
        </NavLink>
        <div
          className={`pointer nav-container${
            isSelectUserMgmt ? " active" : ""
          }${showUserMgmtMenu ? " show" : ""}`}
        >
          <div
            className="nav-container-title-container"
            onClick={() => setShowMenu((prevData) => !prevData)}
          >
            <div
              className={`nav-container-title${
                isSelectUserMgmt ? " active" : ""
              }`}
            >
              {i18n.format(getText("user-management"), "uppercase")}
            </div>
            <CaretDownOutlined
              className={`${isSelectUserMgmt ? "active" : ""}${
                showUserMgmtMenu ? " show" : ""
              }`}
            />
          </div>
          <div className="nav-container-body">
            <NavLink className="nav-menu" to={ROUTE.RESEARCHER}>
              {i18n.format(getText("user-researcher"), "uppercase")}
            </NavLink>
            <NavLink className="nav-menu" to={ROUTE.PARTICIPANT}>
              {i18n.format(getText("user-participant"), "uppercase")}
            </NavLink>
          </div>
        </div>
        <NavLink className="nav-menu" to={ROUTE.USER_VERIFICATION}>
          {i18n.format(getText("user-verification"), "uppercase")}
        </NavLink>
        <NavLink className="nav-menu" to={ROUTE.SURVEY_APPROVAL}>
          {i18n.format(getText("survey-approval"), "uppercase")}
        </NavLink>
        <div
          className={`pointer nav-container${
            isSelectCashoutMhmtMenu ? " active" : ""
          }${showCashoutMhmtMenu ? " show" : ""}`}
        >
          <div
            className="nav-container-title-container"
            onClick={() => setShowCashoutMenu((prevData) => !prevData)}
          >
            <div
              className={`nav-container-title${
                isSelectCashoutMhmtMenu ? " active" : ""
              }`}
            >
              {i18n.format(getText("cashout-management"), "uppercase")}
            </div>
            <CaretDownOutlined
              className={`${isSelectCashoutMhmtMenu ? "active" : ""}${
                showCashoutMhmtMenu ? " show" : ""
              }`}
            />
          </div>
          <div className="nav-container-body">
            <NavLink className="nav-menu" to={ROUTE.CASHOUT_APPROVAL}>
              {i18n.format(getText("cashout-request"), "uppercase")}
            </NavLink>
            <NavLink className="nav-menu" to={ROUTE.CASHOUT_APPROVED}>
              {i18n.format(getText("cashout-approved"), "uppercase")}
            </NavLink>
            <NavLink className="nav-menu" to={ROUTE.EXPORT_HISTORY}>
              {i18n.format(getText("export-history"), "uppercase")}
            </NavLink>
          </div>
        </div>
        <NavLink className="nav-menu" to={ROUTE.TOPUP_HISTORY}>
          {i18n.format(getText("topup-history"), "uppercase")}
        </NavLink>

        <div className="divider my-6"></div>
        <NavLink className="nav-menu" to={ROUTE.REWARDS_APPROVAL}>
          {i18n.format(getText("rewards-approval"), "uppercase")}
        </NavLink>
        <NavLink className="nav-menu" to={ROUTE.ICREDIT_APPROVAL}>
          {i18n.format(getText("i-credit-approval"))}
        </NavLink>
        <NavLink className="nav-menu" to={ROUTE.EMAIL_SUBSCRIPTION}>
          {i18n.format(getText("email-subscription"))}
        </NavLink>
      </div>
      <div id="footer-label-contaner">
        <CommonFooter />
      </div>
    </div>
  );
};

export default Sidebar;
