// for gql query config// for gql query config
import { gql } from "@apollo/client";

// admin dashboard

const DASHBOARD_SUMMARY = gql`
  query dashboardSummary {
    dashboardSummaryAdmin {
      activeProject
      publishToday
      totalProject
      userVerification
      surveyApproval
      cashoutApproval
    }
  }
`;

const PROFILE = gql`
  query getProfile {
    profileAdmin {
      uid
      email
      profilePic
      permission
      firstname
      lastname
      dateOfBirth
      role
      language
      nationality
      address
      identificationNumber
      phoneNumber
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

const LOGIN = gql`
  query login($input: LoginAdminInput!) {
    loginAdmin(input: $input) {
      uid
      accessToken
      refreshToken
      accessTokenExpiresAt
      refreshTokenExpiresAt
    }
  }
`;

const LOGOUT = gql`
  query logout {
    logout {
      messageCode
      message
    }
  }
`;

// project api
const LIST_ALL_PROJECT = gql`
  query listProject($offset: Int, $limit: Int) {
    projectSurveysAdmin(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        uid
        name
        status
        paymentStatus
        favorite
        totalSubmission
        totalParticipantLimit
        totalDone
        totalProgress
        createdBy
        createdAt
        updatedBy
        updatedAt
        publishDate
      }
    }
  }
`;

const LIST_USER_VERIFICATION = gql`
  query listUserVerification($keyword: String!, $offset: Int, $limit: Int) {
    bankVerifications(keyword: $keyword, offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        accountUid
        firstname
        middlename
        lastname
        email
        bank {
          verifyStatus
          updatedAt
          createdAt
        }
      }
    }
  }
`;

const GET_BANK_VERIFICATION_BY_ID = gql`
  query getBankVerificationById($accountUid: String!) {
    bankVerificationById(accountUid: $accountUid) {
      accountUid
      firstname
      lastname
      middlename
      email
      dateOfBirth
      identificationNumber
      language
      phoneNumber
      bank {
        uid
        bankNo
        holderName
        bookBankImageFullPath
        idCardImageFullPath
        bookBankImagePath
        idCardImagePath
        bookBankImageName
        idCardImageName
        provider {
          uid
          bankShortName
          bankOfficialName
          bankNiceNameEN
          bankNiceNameTH
          bankColor
          swiftCode
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
        verifyStatus
        createdAt
        createdBy
        updatedBy
        updatedAt
      }
    }
  }
`;

const LIST_PROJECT_APPOVAL = gql`
  query listProjectApproval($offset: Int, $limit: Int) {
    projectApprovalList(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        projectUid
        surveyType
        name
        status
        publishDate
        updatedAt
        projectOwner {
          email
        }
      }
    }
  }
`;

const GET_PROJECT_APPROVAL_BY_ID = gql`
  query getProjectApprovalByID($projectUid: String!) {
    projectApprovalById(projectUid: $projectUid) {
      projectUid
      surveyType
      name
      description
      device
      status
      totalParticipantLimit
      totalExpense
      totalBudget
      additionalIntensives
      totalAdditionalIncentives
      estimatedDuration
      paymentType
    }
  }
`;

const GET_PREVIEW_PROJECT_DETAIL = gql`
  fragment LogicFragment on LogicApprovalReview {
    logicUid
    uuid
    type
    cubeUuid
    condition {
      uuid
      choiceUuid
      questionUuid
      operator
      value
      questionType
      conditionType
      scaleUuid
      mode
    }
    randomlyPresent
    order
  }
  query getProjectApprovalPreivew($projectUid: String!) {
    projectApprovalById(projectUid: $projectUid) {
      projectUid
      name
      logic {
        ...LogicFragment
        logic {
          ...LogicFragment
          logic {
            ...LogicFragment
            logic {
              ...LogicFragment
            }
          }
        }
      }
      cube {
        cubeUid
        uuid
        cubeName
        minimumTime
        maximumTime
        order
        question {
          questionUid
          uuid
          title
          type
          answerType
          format
          matrixType
          slideType
          mandatory
          scale {
            scaleUid
            uuid
            label
            point
            naming
            order
          }
          maxStar
          min
          max
          gridLines
          videoUrl
          order
          answer {
            answerUid
            value
            value
          }
          choice {
            choiceUid
            uuid
            label
            rightLabel
            order
          }
          imageName
          imageUrl
          imagePath
          imageType
        }
      }
    }
  }
`;

const GET_AUDIENCE = gql`
  query audienceAdmin($projectUid: String!) {
    audiencesAdmin(projectUid: $projectUid) {
      audienceUid
      typeUid
      typeName
      need {
        questionSubject
        choice {
          choiceValue
        }
      }
      needless {
        questionSubject
        choice {
          choiceValue
        }
      }
    }
  }
`;

const LIST_PARTICIPANTS = gql`
  query listParticipant($keyword: String!, $offset: Int, $limit: Int) {
    participantListAdmin(keyword: $keyword, offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        uid
        firstname
        middlename
        lastname
        email
        dateOfBirth
        identificationNumber
        phoneNumber
      }
    }
  }
`;

const LIST_RESEARCHER = gql`
  query listResearcher($keyword: String!, $offset: Int, $limit: Int) {
    researcherListAdmin(keyword: $keyword, offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        uid
        firstname
        middlename
        lastname
        email
        dateOfBirth
        identificationNumber
        phoneNumber
        role
      }
    }
  }
`;

const GET_PARTICIPANT_DETAIL = gql`
  query getParticipant($uid: String!) {
    participantAdmin(participantUid: $uid) {
      uid
      firstname
      middlename
      lastname
      email
      dateOfBirth
      identificationNumber
      phoneNumber
      changeEmailPending
      changeEmailStatus
      bankVerification {
        uid
        bankNo
        holderName
        bookBankImageFullPath
        idCardImageFullPath
        bookBankImagePath
        idCardImagePath
        bookBankImageName
        idCardImageName
        provider {
          uid
          bankShortName
          bankOfficialName
          bankNiceNameEN
          bankNiceNameTH
          bankColor
          swiftCode
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
        verifyStatus
        createdAt
        createdBy
        updatedBy
        updatedAt
      }
      screening {
        uid
        typeName
        order
        question {
          uid
          questionTitle
          questionSubject
          answerColumn
          multiAnswer
          order
          createdBy
          createdAt
          updatedBy
          updatedAt
          choice {
            uid
            choiceValue
            specifyAnswer
            order
            checked
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
          specify {
            value
          }
        }
      }
    }
  }
`;

const GET_RESEARHER_DETAIL = gql`
  query getResearcher($uid: String!) {
    researcherAdmin(researcherUid: $uid) {
      uid
      firstname
      middlename
      lastname
      email
      dateOfBirth
      identificationNumber
      phoneNumber
      changeEmailPending
      changeEmailStatus
    }
  }
`;

const LIST_PROJECT_RESEARHER = gql`
  query researcherProjectList(
    $offset: Int
    $limit: Int
    $researcherUid: String!
  ) {
    researcherProjectListAdmin(
      offset: $offset
      limit: $limit
      researcherUid: $researcherUid
    ) {
      totalRecord
      currentPage
      data {
        uid
        name
        createdAt
        publishDate
        status
      }
    }
  }
`;
const LIST_CASHOUT_APPROVAL = gql`
  query listCashoutApprval($offset: Int, $limit: Int) {
    cashOutApprovalListAdmin(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        uid
        owner {
          firstname
          middlename
          lastname
          email
        }
        createdAt
        updatedAt
        transactionStatus
        transactionAmount
      }
    }
  }
`;
const LIST_CASHOUT_APPROVED = gql`
  query listCashoutApprved(
    $keyword: String
    $offset: Int
    $limit: Int
    $date: [String!]!
    $order: String
  ) {
    cashOutApprovedListAdmin(
      keyword: $keyword
      offset: $offset
      limit: $limit
      date: $date
      order: $order
    ) {
      totalRecord
      currentPage
      data {
        uid
        owner {
          firstname
          middlename
          lastname
          email
        }
        createdAt
        updatedAt
        exportStatus
        transactionStatus
        transactionAmount
      }
    }
  }
`;

const LIST_EXPORT_HISTORY = gql`
  query listExportHistory($offset: Int, $limit: Int) {
    exportHistoryListAdmin(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        uid
        fileName
        filePath
        createdAt
        createdBy
      }
    }
  }
`;

const GET_CASHOUT_DETAIL = gql`
  query getCashoutDetail($uid: String!) {
    cashOutApprovalInfoAdmin(transactionUid: $uid) {
      uid
      balanceBeforeWithdraw
      createdAt
      transactionAmount
      transactionAmountSummary
      transactionFee
      transactionWHT
      transactionStatus
      owner {
        uid
        firstname
        middlename
        lastname
        dateOfBirth
        identificationNumber
        email
        phoneNumber
        bankVerification {
          uid
          bankNo
          holderName
          bookBankImageFullPath
          idCardImageFullPath
          bookBankImagePath
          idCardImagePath
          bookBankImageName
          idCardImageName
          provider {
            uid
            bankNiceNameEN
            bankNiceNameTH
            createdBy
            createdAt
            updatedBy
            updatedAt
          }
          verifyStatus
          createdAt
          createdBy
          updatedBy
          updatedAt
        }
      }
    }
  }
`;

const LIST_TOPUP = gql`
  query listTopup($offset: Int, $limit: Int) {
    topUpHistoryListAdmin(offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      data {
        uid
        transactionName
        transactionAmount
        createdAt
        invoiceRef1
        invoiceRef2
        invoiceRef3
        invoiceId
        owner {
          firstname
          middlename
          lastname
        }
      }
    }
  }
`;

const VERIFY_FORGOT = gql`
  query verifyForgotPasswordAdmin($verifyKey: String!) {
    verifyForgotPasswordAdmin(verifyKey: $verifyKey) {
      messageCode
      message
    }
  }
`;

const LIST_REWARD = gql`
  query rewardsByAdmin($keyword: String!, $offset: Int, $limit: Int) {
    rewardsByAdmin(keyword: $keyword, offset: $offset, limit: $limit) {
      totalRecord
      currentPage
      limit
      totalPage
      data {
        uid
        name
        code
        status
        description
        expirationDate
        limitPerson
        numberPrizes
        numberRedeem
        receiptDescription
        rewardFiles {
          fileName
          filePath
          uid
        }
        createdAt
        createdBy
        redeems {
          uid
          code
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
        updatedAt
        updatedBy
        account {
          email
          firstname
          lastname
        }
      }
    }
  }
`;

const GET_REWARD_APPROVAL_BY_ID = gql`
  query reward($id: String!) {
    reward(id: $id) {
      uid
      name
      description
      numberRedeem
      numberPrizes
      limitPerson
      status
      receiptDescription
      expirationDate
      expirationDateUnlimited
      numberPrizeUnlimited
      limitPersonUnlimited
      code
      rewardFiles {
        fileName
        filePath
      }
      redeems {
        uid
        createdBy
        updatedBy
        updatedAt
        createdAt
        account {
          uid
          email
          firstname
          lastname
          role
        }
        status
      }
      account {
        uid
        firstname
        lastname
        email
        role
      }
    }
  }
`;

const LIST_PARTNERORGANIZATION = gql`
  query PartnerOrganizations(
    $keyword: String
    $offset: Int
    $limit: Int
    $date: [String!]!
    $order: String
  ) {
    partnerOrganizations(
      keyword: $keyword
      offset: $offset
      limit: $limit
      date: $date
      order: $order
    ) {
      currentPage
      data {
        uid
        name
        email
        currentCredit
        totalAccount
        members {
          uid
          role
          firstname
          lastname
          email
        }
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
      limit
      totalPage
      totalRecord
    }
  }
`;
const GET_PARTNERORGANIZATION_BY_ID = gql`
  query partnerOrganizationById(
    $uid: String!
    $keyword: String
    $offset: Int
    $limit: Int
    $date: [String!]!
    $order: String
  ) {
    partnerOrganizationById(
      uid: $uid
      keyword: $keyword
      offset: $offset
      limit: $limit
      date: $date
      order: $order
    ) {
      currentPage
      data {
        uid
        name
        email
        currentCredit
        totalAccount
        address
        country
        identificationNumber
        phoneNumber
        createdBy
        createdAt
        updatedBy
        updatedAt
        topupAt
        members {
          uid
        }
        transaction {
          createdAt
          uid
          email
          transactionStatus
          transactionName
          transactionType
          transactionAmount
          transactionAmountSummary
        }
        contact {
          uid
          name
          email
          gender
          dateOfBirth
          identificationNumber
          nationality
          phoneNumber
          address
          createdBy
          createdAt
          updatedBy
          updatedAt
        }
      }
      limit
      totalPage
      totalRecord
      members {
        uid
        firstname
        middlename
        lastname
        email
        role
        createdAt
        updatedAt
        topupAt
        currentCredit
      }
      order
    }
  }
`;
const GET_SUBSCRIBES = gql`
  query Subscribes(
    $keyword: String
    $order: String
    $offset: Int
    $limit: Int
  ) {
    subscribes(
      keyword: $keyword
      order: $order
      offset: $offset
      limit: $limit
    ) {
      totalRecord
      currentPage
      limit
      totalPage
      data {
        uid
        emailSubscribe
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

export {
  DASHBOARD_SUMMARY,
  LIST_ALL_PROJECT,
  PROFILE,
  LOGIN,
  LOGOUT,
  LIST_USER_VERIFICATION,
  GET_BANK_VERIFICATION_BY_ID,
  LIST_PROJECT_APPOVAL,
  GET_PROJECT_APPROVAL_BY_ID,
  GET_PREVIEW_PROJECT_DETAIL,
  LIST_PARTICIPANTS,
  LIST_RESEARCHER,
  GET_PARTICIPANT_DETAIL,
  GET_RESEARHER_DETAIL,
  LIST_PROJECT_RESEARHER,
  LIST_CASHOUT_APPROVAL,
  LIST_CASHOUT_APPROVED,
  GET_CASHOUT_DETAIL,
  LIST_TOPUP,
  GET_AUDIENCE,
  VERIFY_FORGOT,
  LIST_EXPORT_HISTORY,
  LIST_REWARD,
  GET_REWARD_APPROVAL_BY_ID,
  LIST_PARTNERORGANIZATION,
  GET_PARTNERORGANIZATION_BY_ID,
  GET_SUBSCRIBES,
};
