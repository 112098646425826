const setCurrentUser = (oldState, payload) => {
  const { currentUser: newCurrentUser } = payload;
  return {
    ...oldState,
    currentUser: newCurrentUser,
  };
};

const clearCurrentUser = (oldState) => {
  return {
    ...oldState,
    currentUser: {},
  };
};

const showModal = (oldState, payload) => {
  const { modalConfig: newModalConfig } = payload;
  return {
    ...oldState,
    isShowModal: true,
    modalConfig: newModalConfig,
  };
};
const hideModal = (oldState) => {
  return {
    ...oldState,
    isShowModal: false,
    modalConfig: null,
  };
};

const showGlobalLoading = (oldState, payload) => {
  return {
    ...oldState,
    globalLoading: true,
  };
};

const hideGlobalLoading = (oldState, payload) => {
  return {
    ...oldState,
    globalLoading: false,
  };
};
const showTopupModal = (oldState, payload) => {
  return {
    ...oldState,
    isTopupModal: true,
  };
};

const hideTopupModal = (oldState, payload) => {
  return {
    ...oldState,
    isTopupModal: false,
  };
};
const setAccountSelected = (oldState, payload) => {
  return {
    ...oldState,
    accountSelected: { ...oldState.accountSelected, ...payload?.payload },
  };
};
const hideAccountSelected = (oldState, payload) => {
  return {
    ...oldState,
    accountSelected: {},
  };
};

export {
  setCurrentUser,
  clearCurrentUser,
  showModal,
  hideModal,
  showGlobalLoading,
  hideGlobalLoading,
  showTopupModal,
  hideTopupModal,
  setAccountSelected,
  hideAccountSelected,
};
