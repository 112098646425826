const ACTIONS = {
  SET_CURRENT_USER: "set-current-user",
  CLEAR_CURRENT_USER: "clear-current-user",
  SHOW_CONFIRM_MODAL: "show-confirm-modal",
  HIDE_CONFIRM_MODAL: "hide-confirm-modal",
  SHOW_GLOBAL_LOADING: "show-global-loading",
  HIDE_GLOBAL_LOADING: "hide-global-loading",
  SHOW_TOPUP_MODAL: "show-topup-modal",
  HIDE_TOPUP_MODAL: "hide-topup-modal",
  SET_ACCOUNT_SELECTED: "set-account-selected",
  HIDE_ACCOUNT_SELECTED: "hide-account-selected",
};

export { ACTIONS };
